export enum EFormFieldName {
  StartDate = 'startDate',
  Name = 'name',
  EndDate = 'endDate',
  Cost = 'cost',
  Model = 'model',
  HoursOrPercantage = 'hoursOrPercantage',
  Consultant = 'consultant',
  HolidayCalendarId = 'holidayCalendarId',
  Rate = 'rate',
  CostOfOverhead = 'costOfOverhead',
  Fee = 'fee',
  ShowInBudget = 'showInBudget',
  Client = 'client',
  ClientId = 'clientId',
  Notes = 'notes',
  Title = 'title',
  Role = 'role',
  AddText = 'addText',
  Label = 'label',
  Status = 'status',
  Id = 'id',
  BudgetingSettingId = 'budgetingSettingId',
  ProjectId = 'projectId',
  ProjectName = 'projectName',
  Email = 'email',
  Password = 'password',
  ForecastToCreate = 'forecastToCreate',
  DeliveryManagerId = 'deliveryManagerId',
  AccountId = 'accountId',
  Calendar = 'calendar',
  CCY = 'ccy',
  Allocation = 'allocation',
  Hours = 'hours',
  Year = 'year',
}
