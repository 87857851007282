import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLocalization } from '@fluent/react';
import cn from 'classnames';
import { showNotification } from 'src/common/apolloState/notifications';
import { RequestStatus } from 'src/common/types';
import { secondsToMilliSeconds } from 'src/common/utils/secondsToMilliSeconds';
import DragAndDrop from 'src/components/DragAndDrop/DragAndDrop';
import { useConsultantsLatestUpdatedFile } from 'src/pages/ManageSettings/ConsultantsSettings/hooks/LastUpdatedConsultantsFile.hook';
import { useUploadConsultantsFile } from 'src/pages/ManageSettings/ConsultantsSettings/hooks/UploadConsultantsFile.hook';

import { Box, Button, Typography } from '@mui/material';

import {
  BROWSE_FILES,
  CONSULTANTS_LAST_UPDATED_FILE,
  DRAG_AND_DROP_HERE,
  FORMAT_UPLOAD_MESSAGE_CONSULTANTS,
  OR,
  SUCCESS_CONSULTANTS_UPLOAD_MESSAGE,
  UPLOAD_FTL,
} from 'valtech-core/common/ftl';
import { AlertsSeverityEnum } from 'valtech-core/common/types';
import ProgressLinear from 'valtech-core/ui/ProgressLinear';

import s from './ConsultantsSettings.module.scss';
import { CONSULTANTS_UPLOAD_FORMAT } from './hooks/UploadConsultantsFile.constants';

const ConsultantsSettings: FC = () => {
  const { l10n } = useLocalization();
  const { uploadConsultantsFile, status } = useUploadConsultantsFile();
  const { consultantsLastUpdatedAt, error } = useConsultantsLatestUpdatedFile();

  const uploadedFile = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const extensionsRegex = useMemo(() => /\.(csv|xlsx)/g, []);

  const handleClearData = useCallback(
    (uploadedFile: React.RefObject<HTMLInputElement>) => {
      setTitle('');
      setFile(null);

      if (uploadedFile.current) {
        uploadedFile.current.value = '';
      }
    },
    [uploadedFile],
  );

  const handleSendFile = useCallback(() => {
    if (file) {
      uploadConsultantsFile({
        file,
      });
    }
  }, [file]);

  useEffect(() => {
    if (status == RequestStatus.Success) {
      showNotification({
        message: l10n.getString(SUCCESS_CONSULTANTS_UPLOAD_MESSAGE),
        hideDuration: secondsToMilliSeconds(3),
        severity: AlertsSeverityEnum.Success,
      });
    }

    if (status == RequestStatus.Success || status == RequestStatus.Error) {
      handleClearData(uploadedFile);
    }
  }, [status]);

  return (
    <>
      {status === RequestStatus.Pending && (
        <ProgressLinear
          boxProps={{
            sx: {
              width: '100%',
              position: 'absolute',
              top: '52px',
              left: 0,
            },
          }}
        />
      )}
      <form onSubmit={e => e.preventDefault()} id='form-file-upload'>
        <div className={cn(s.titleWrapper, { [s.active]: title })}>
          <input value={title || 'Title'} className={s.inputTitle} readOnly disabled type='text' />
        </div>

        <DragAndDrop
          render={(dragActive, handleDrop, handleDrag, handleChange) => (
            <>
              <input
                onChange={e =>
                  handleChange(e, setTitle, setFile, CONSULTANTS_UPLOAD_FORMAT, extensionsRegex)
                }
                className={s.inputFile}
                id='input-file-upload'
                accept='.csv, .xlsx'
                type='file'
                ref={uploadedFile}
              />
              <label
                id='label-file-upload'
                htmlFor='input-file-upload'
                className={cn({ 'drag-active': dragActive })}>
                <div className={s.dragNDropWrapper} onDragEnter={handleDrag}>
                  <p>{l10n?.getString(DRAG_AND_DROP_HERE)}</p>
                  <p>{l10n?.getString(OR)}</p>
                  <p className={s.uploadFileText}>{l10n?.getString(BROWSE_FILES)}</p>
                </div>
                {dragActive && (
                  <div
                    className={s.dragNDrop}
                    id='drag-file-element'
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={e =>
                      handleDrop(e, setTitle, setFile, CONSULTANTS_UPLOAD_FORMAT, extensionsRegex)
                    }></div>
                )}
                <Typography variant='h6' className={s.fileTypeWarning}>
                  {l10n.getString(FORMAT_UPLOAD_MESSAGE_CONSULTANTS)}
                </Typography>
              </label>
            </>
          )}
          handleClearData={() => handleClearData(uploadedFile)}
          message={FORMAT_UPLOAD_MESSAGE_CONSULTANTS}
        />

        <Button
          disabled={!file}
          type='button'
          size='medium'
          variant='contained'
          onClick={() => handleSendFile()}>
          {l10n?.getString(UPLOAD_FTL)}
        </Button>
        {consultantsLastUpdatedAt && !error && (
          <Box className={s.fileLastUpdatedAtWrapper}>
            <Typography
              variant='h6'
              align='inherit'
              component={'span'}
              className={s.fileLastUpdatedAtWrapper__title}>
              {l10n.getString(CONSULTANTS_LAST_UPDATED_FILE)}&nbsp;
            </Typography>
            <Typography variant='h6' align='inherit' component={'span'}>
              {consultantsLastUpdatedAt}
            </Typography>
          </Box>
        )}
      </form>
    </>
  );
};

export default ConsultantsSettings;
