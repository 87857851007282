import { Dictionary } from 'valtech-core/common/types';

export const NO_DATA_FTL = 'no-data-text';
export const EMPTY_BUDGETING_FTL = 'empty-budgeting-text';
export const CONFIRMATION_MESSAGE_FTL = 'confirmation-message';

export const LOGIN_FTL = 'login';
export const LOGOUT_FTL = 'logout';

export const EUR_TO_USD_FTL = 'eur-to-usd';
export const USD_TO_EUR_FTL = 'usd-to-eur';

export const OK_FTL = 'ok';
export const SAVE_FTL = 'save';
export const DELETE_FTL = 'delete';
export const ADD_FTL = 'add';
export const ADD_MORE_FTL = 'add-more';
export const SUBMIT_FTL = 'submit';
export const CANCEL_FTL = 'cancel';
export const CONFIRM_FTL = 'confirm';
export const EDIT_FTL = 'edit';
export const INVOICE_FTL = 'invoice';
export const ADD_ACCOUNT_FTL = 'add-account';
export const ADD_PROJECT_FTL = 'add-project';
export const SELECT_ACCOUNT_FLT = 'select-account';
export const CREATE_NEW_ACCOUNT_FTL = 'create-new-account';
export const CREATE_NEW_PROJECT_FTL = 'create-new-project';
export const CLEAR_ALL_FTL = 'clear-all';
export const ADD_ASSIGNMENT_FTL = 'add-assignment';
export const COLLAPSE_FTL = 'collapse';
export const SHOW_ALL_FTL = 'show-all';
export const INCLUDE_FTL = 'include';
export const IGNORE_FTL = 'ignore';
export const UNDO_FTL = 'undo';
export const PROCEED_FTL = 'proceed';
export const FINANCIAL_YEAR_LABEL_FTL = 'financial-year-label';
export const CALENDAR_FTL = 'calendar';
export const UPLOAD_FTL = 'upload';
export const REPLACE_FTL = 'replace';

export const LABEL_GENDER = 'label-gender';
export const LABEL_ROLE = 'label-role';

export const DRAG_AND_DROP_HERE = 'drag-and-drop-here';
export const BROWSE_FILES = 'browse-file';
export const OR = 'or';
export const FORMAT_UPLOAD_MESSAGE_CONSULTANTS = 'format-upload-message-consultants';
export const FORMAT_UPLOAD_MESSAGE_TIMESHEETS = 'format-upload-message-timesheets';

export const CONSULTANT_FTL = 'consultant';
export const GENERAL_FTL = 'general';
export const MODEL_FTL = 'model';
export const HOURS_OR_PERCANTAGE_FTL = 'hours-or-percantage';
export const HOLIDAY_CALENDAR_FTL = 'holiday-calendar';
export const HOLIDAY_FTL = 'holiday';
export const COST_OF_OVERHEAD_FTL = 'cost-of-overhead';
export const RATE_FTL = 'rate';
export const RATES_FTL = 'rates';
export const EXCHANGE_RATE_FTL = 'exchange-rate';
export const TIER_FTL = 'tier';
export const FEE_FTL = 'fee';
export const DATE_FTL = 'date';
export const START_DATE_FTL = 'start-date';
export const END_DATE_FTL = 'end-date';
export const CREATE_ICBO_FTL = 'create-icbo';
export const SEND_ICBO_FTL = 'send-icbo';
export const ICBO_TEMPLATE_FTL = 'icbo-template';
export const SHOW_IN_BUDGET_FTL = 'show-in-budget';
export const DOCUMENT_SIGN_FTL = 'docusign-id';
export const ACCESS_LEVEL_FTL = 'access-level';
export const ACCESS_LEVEL_EDIT_FTL = 'access-level-editing';
export const ACCESS_LEVEL_EDIT_AND_BILLING_FTL = 'access-level-editing-and-billing';
export const ACCESS_LEVEL_VIEW_AND_COMMENT_FTL = 'access-level-view-and-comment';
export const ADD_TEXT_FTL = 'add-text';
export const CLIENT_FTL = 'client';
export const NOTES_FTL = 'notes';
export const ROLE_FTL = 'role';
export const ROLE_TITLE_FTL = 'role-title';
export const TITLE_FTL = 'title';
export const COST_FTL = 'cost';
export const ALLOCATION_FTL = 'allocation';
export const WRITE_COMMENT_FTL = 'write-comment';
export const ADD_COMMENT_FTL = 'add-comment';
export const UNSAVED_CHANGES_FTL = 'warning-unsaved-changes';
export const ARE_YOU_SURE_FTL = 'are-you-sure';
export const BUDGET_WAS_CREATED_FTL = 'budget-created';
export const CHANGES_WERE_SAVED_FTL = 'changes-saved';
export const CALENDAR_WAS_CREATED_FTL = 'calendar-created';

export const BUDGETS_FTL = 'budgets';
export const PROJECT_FTL = 'project';
export const PROJECTS_FTL = 'projects';
export const ACCOUNT_FTL = 'account';
export const ACCOUNTS_FTL = 'accounts';
export const DELIVERY_MANAGER_FTL = 'delivery-manager';
export const GENERAL_MANAGER_FTL = 'general-manager';
export const LAST_UPDATE_FTL = 'last-update';
export const LAST_UPDATED_FTL = 'last-updated';
export const UPDATED_BY_FTL = 'updated-by';
export const UPDATED_AT_FTL = 'updated-at';
export const UPLOADED_BY_FTL = 'uploaded-by';
export const UPLOAD_DATE_FTL = 'upload-date';
export const STATUS_FTL = 'status';
export const BILL_FTL = 'bill';
export const TOTAL_FTL = 'total';
export const NAME_FTL = 'name';
export const SHORT_NAME_FLT = 'short-name';
export const LABEL_FTL = 'label';
export const ACCOUNT_MANAGER_FTL = 'account-manager';
export const CLIENT_MANAGER_FTL = 'client-manager';
export const TYPE_FTL = 'type';
export const ALL_FTL = 'all';
export const ACTION_FTL = 'action';

export const TIMESHEET_FTL = 'timesheet';
export const BUDGETING_FTL = 'budgeting';
export const BUDGET_FTL = 'budget';
export const ENTITY_FTL = 'entity';
export const ENTITIES_FTL = 'entities';
export const ADD_ENTITY_FTL = 'add-entity';
export const EDIT_ENTITY_FTL = 'edit-entity';
export const USERS_FTL = 'users';
export const USER_FTL = 'user';
export const ADD_USER_FTL = 'add-user';
export const EDIT_USER_FTL = 'edit-user';
export const REPORT_CORRECTION_FTL = 'report-correction';
export const OVERTIME_FTL = 'overtime';
export const CONSULTANTS_FTL = 'consultants';
export const VACANCIES_FTL = 'vacancies';
export const EXPENSES_FTL = 'expenses';
export const VACATION_BALANCE_FTL = 'vacation-balance';
export const BILLING_HISTORY_FTL = 'billing-history';
export const USER_ACCESS_LEVEL_FTL = 'user-access-level';
export const MSA_DETAILS_FTL = 'msa-details';

export const FIRST_NAME_FTL = 'first-name';
export const LAST_NAME_FTL = 'last-name';
export const EMAIL_FTL = 'email';
export const PASSWORD_FTL = 'password';
export const CURRENCY_FTL = 'currency';
export const FINANCIAL_YEAR_FTL = 'financal-year';
export const BUSINESS_UNIT_ID_FTL = 'business-unit-id';
export const BUSINESS_UNIT_FTL = 'business-unit';
export const SIGNATURE_DATE_FTL = 'signature-date';
export const ASSOCIATED_CLIENTS_FTL = 'associated-clients';
export const PROJECT_NAME_IN_OPEN_AIR = 'project-name-in-open-air';
export const MONTH_FTL = 'month';
export const HOURS_FTL = 'hours';
export const PER_MONTH_FTL = 'per-month-hours';
export const WORK_SCHEDULE_FTL = 'work-schedule';
export const UPD_BY_FTL = 'upd-by';

export const TOTAL_EXPENSES_FTL = 'total-expenses';
export const INVOICED_TOTAL_BY_ENTITIES_FLT = 'invoiced-total-by-entities';
export const CONSULTANTS_CLIENTS_FTL = 'consultants-clients';
export const CLIENTS_EXPENSES_FTL = 'clients-expenses';
export const TOTAL_FOR_INVOICING_FTL = 'total-for-invoicing';
export const ACTUAL_GM = 'budgeting-edit-actual-gm';
export const PERIOD_FTL = 'period';
export const QTY_FTL = 'qty';
export const AMOUNT_FTL = 'amount';
export const MULTIPLIER_FTL = 'multiplier';
export const VACATION_FTL = 'vacation';

export const BUDGET_CONST_OF_OVERHEADS_FTL = 'default-cost-of-overhead';
export const BUDGET_HOURS_PER_MONTH_FTL = 'hrs-per-month';

export const STATUS_ACTIVE_FTL = 'status-active';
export const STATUS_NOT_ACTIVE_FTL = 'status-not-active';
export const STATUS_ARCHIVED_FTL = 'status-archived';
export const STATUS_CREATED_FTL = 'status-created';
export const STATUS_SENT_FTL = 'status-sent';
export const STATUS_DRAFT_FTL = 'status-draft';
export const STATUS_SIGNED_FTL = 'status-signed';
export const MODEL_DEDICATED_FTL = 'model-dedicate';
export const MODEL_TM_FTL = 'model-t-&-m';

const JAN = 'calendar-month-january';
const FEB = 'calendar-month-february';
const MAR = 'calendar-month-march';
const APR = 'calendar-month-april';
const MAY = 'calendar-month-may';
const JUN = 'calendar-month-june';
const JUL = 'calendar-month-july';
const AUG = 'calendar-month-august';
const SEP = 'calendar-month-september';
const OCT = 'calendar-month-october';
const NOV = 'calendar-month-november';
const DEC = 'calendar-month-december';

export const JAN_SHORT_FTL = 'calendar-month-short-january';
export const DEC_SHORT_FTL = 'calendar-month-short-december';

export const MONTH_LIST: string[] = [JAN, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV, DEC];

export const BILLING_SUCCESSFUL_MESSAGE_FTL = 'submit-billing-success-message';
export const SUCCESS_CONSULTANTS_UPLOAD_MESSAGE = 'success-consultants-upload-message';

export const GENERIC_ERROR = 'generic-error-text';
export const DELETE_ERROR_FTL = 'delete-error-text';
export const PERIOD_ERROR_FTL = 'error-period';
export const ERROR_ASSOCIATED_CLIENT_EXIST = 'error-associated-client-exist';
export const ERROR_CREDENTIALS_USER_EXIST = 'error-credentials-user-exist';
export const ERROR_USER_WITH_EMAIL_EXIST = 'error-user-with-email-exist';
export const ERROR_BUDGETING_YEAR_EXIST = 'error-budgeting-year-exist';
export const ERROR_BUDGET_FOR_PROJECT_EXIST_FTL = 'error-budget-for-project-exist';
export const ERROR_BUISINESS_UNIT_ALREADY_EXISTS = 'error-business-unit-already-exists';
export const ERROR_HOLIDAY_CALENDAR_EXIST = 'error-holiday-calendar-exist';
export const ERROR_PROJECT_NAME_EXIST = 'error-project-name-exist';
export const ERROR_LOGIN_UNAUTHORIZED = 'error-login-unauthorized';
export const ERROR_FILE_EXIST_TITLE_FTL = 'error-file-exist-title';
export const ERROR_FILE_EXIST_TEXT_FTL = 'error-file-exist-text';

export const ERROR_INVALID_TITLE_FTL = 'error-invalid-title';
export const ERROR_REQUIRED_FTL = 'error-required';
export const ERROR_REQUIRED_WITH_CONDITIONS_FTL = 'error-required-with-conditions';
export const ERROR_REQUIRED_SHORT_FTL = 'error-required-short';
export const ERROR_SHORT_STR_FTL = 'error-short-str';
export const ERROR_MID_STR_LIMIT_FTL = 'error-mid-limit-str';
export const ERROR_NOTE_STR_LIMIT_FTL = 'error-note-str-limit-str';
export const ERROR_LONG_STR_FTL = 'error-long-str';
export const ERROR_MIN_NUMBER_FTL = 'error-min-number';
export const ERROR_EMAIL_FTL = 'error-email';
export const ERROR_EMPTY_RATES_FIELDS_FTL = 'error-empty-rates-status';
export const ERROR_NUMBER_FORMAT_FTL = 'error-invalid-number-format';
export const ERROR_HOURS_LIMIT_FTL = 'error-hours-limit-format';
export const ERROR_AMOUNT_LIMIT_FTL = 'error-amount-limit-format';
export const ERROR_EXCHANGE_RATE_FORMAT_FTL = 'error-exchange-rate-format';
export const ERROR_OVERHEADS_FTL = 'error-invalid-overheads';
export const ERROR_REQUIRED_OPTION_FTL = 'error-required-option';
export const ERROR_EXISTING_DATE_FTL = 'error-existing-date';
export const ERROR_LETTERS_ONLY_FTL = 'error-invalid-string-letters-only';
export const ERROR_LETTERS_AND_NUMBERS_ONLY_FTL = 'error-invalid-letters-and-numbers-only';
export const ERROR_NO_ASSIGNMENTS_IN_BUDGETING = 'error-no-assignments-in-budgeting';
export const ERROR_CONSULTANTS_UPLOAD_MESSAGE = 'error-consultants-upload-message';
export const ERROR_WARNINGS = 'error-warnings';

export const ERROR_NO_TIMESHEETS_FOUND_FTL = 'error-no-timesheets-found';
export const ERROR_TIMESHEET_DO_NOT_MATCH_THE_FORECAST_FOUND_FTL =
  'error-timesheets-do-not-match-the-forecast';

export const ERRORS_FTL: Dictionary<string> = {
  'value too long for type character varying(45)': ERROR_MID_STR_LIMIT_FTL,
  "You can't add assignment for this period": PERIOD_ERROR_FTL,
  "You can't edit assignment for this period": PERIOD_ERROR_FTL,
  "You can't add expense for this period": PERIOD_ERROR_FTL,
  "You can't edit expense for this period": PERIOD_ERROR_FTL,
  'financial year must be unique': ERROR_BUDGETING_YEAR_EXIST,
  'Budget for this project already exists': ERROR_BUDGET_FOR_PROJECT_EXIST_FTL,
  'Business unit already exists': ERROR_BUISINESS_UNIT_ALREADY_EXISTS,
  [`${DELETE_ERROR_FTL}`]: DELETE_ERROR_FTL,
};

// Countries
export const COUNTRY_UA_FTL = 'ua';
export const COUNTRY_PL_FTL = 'pl';
export const COUNTRY_RO_FTL = 'ro';

// Budgeting
export const BUDGETING_PROCEED_FTL = 'budgeting-proceed-message';

// Holiday calendar
export const HOLIDAY_CALENDAR_PROCEED_FTL = 'holiday-calendar-proceed-message';

// Associated clients
export const ASSOCIATED_CLIENTS_PROCEED_FTL = 'associated-clients-proceed-message';

// Settings Consultants
export const CONSULTANTS_LAST_UPDATED_FILE = 'last-updated-at';
