import { useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useBudgetingState } from 'common/apolloState/budgeting';
import { showSystemError } from 'common/apolloState/system';
import { ConsultantData, useAllConsultants } from 'common/hooks/useAllConsultants';
import { useClients } from 'common/hooks/useClients';
import { useHolidayCalendarList } from 'common/hooks/useHolidayCalendarList';
import { RateItem, useRateList } from 'common/hooks/useRateList/useRateList';
import { ConsultantModelEnum } from 'common/types';

import {
  RecordStatus,
  useAddAssignmentMutation,
  useAddNotesMutation,
  useEditAssignementMutation,
  useUpdateBudgetMutation,
} from 'valtech-core/common/gql/generated';
import { Maybe } from 'valtech-core/common/types';
import { getErrorMessage } from 'valtech-core/common/utils/getErrorMessage';
import { SelectedItem } from 'valtech-core/form/Select';

import { useDeleteAssignment } from 'components/_forms/forms.hooks';

import { CUSTOM_TIER } from '../forms.utils';
import {
  mapToSubmitAddAssignment,
  mapToSubmitAddAssignmentFromBilling,
  mapToSubmitEditAssignment,
} from './AssignmentForm.utils';

import { EFormFieldName } from '../form.types';
import { IAssignmentFormProps } from './AssignmentForm';

type UseHandleAssignmentReturn = {
  createAssignment: (data: IAssignmentFormProps, fromBilling?: boolean) => Promise<boolean>;
  updateAssignment: ({ ...params }: UpdateAssignmentParams) => Promise<boolean>;
  deleteAssignment: (assignmentId: Maybe<number>, fromBilling?: boolean) => Promise<boolean>;
  updateNotes: (assignmentId: Maybe<number>, data: string) => Promise<boolean>;
  clientsList: SelectedItem[];
  rateList: { dedicated: SelectedItem[]; timeAndMaterial: SelectedItem[] };
  rates: { dedicated: RateItem[]; timeAndMaterial: RateItem[] };
  modelList: SelectedItem[];
  holidayCalendarList: SelectedItem[];
  consultantsList: ConsultantData[];
  loading: boolean;
};

type UseHandleAssignmentProps = {
  selectedConsultantEntity?: string;
};

type UpdateAssignmentParams = {
  assignmentId: Maybe<number>;
  data: IAssignmentFormProps;
  fromBilling?: Maybe<boolean>;
  billingReportId?: Maybe<number>;
  ccy?: Maybe<string>;
  icboTemplateId?: Maybe<number>;
};

export const useHandleAssignment = ({
  selectedConsultantEntity,
}: UseHandleAssignmentProps): UseHandleAssignmentReturn => {
  const {
    settingId: budgetingSettingId,
    budgetReportId,
    projectId,
    financialYear,
    currency,
  } = useBudgetingState();
  const { id } = useParams();
  const pageId = Number(id);

  const { consultants: consultantsList } = useAllConsultants();
  const { clients } = useClients();
  const { rates, loading: loadingRates } = useRateList({ currency });

  const { holidayCalendar } = useHolidayCalendarList({
    filter: [
      { field: EFormFieldName.Status, query: RecordStatus.Active },
      { field: EFormFieldName.Year, query: `${financialYear}` },
    ],
  });

  const [addAssignmentMutation, { loading: adding }] = useAddAssignmentMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [editAssignmentMutation, { loading: updating }] = useEditAssignementMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const [addNotesMutation, { loading: updatingNotes }] = useAddNotesMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const { loading: deleting, deleteAssignment } = useDeleteAssignment();

  const clientsList = useMemo(
    (): SelectedItem[] =>
      clients.map(client => ({ id: client.id, title: client.name, value: `${client.id}` })),
    [clients],
  );

  const rateList = useMemo((): { dedicated: SelectedItem[]; timeAndMaterial: SelectedItem[] } => {
    const filteredRates = selectedConsultantEntity
      ? {
          dedicated: rates.dedicated.filter(
            rate => rate.entity === selectedConsultantEntity || rate.label === CUSTOM_TIER,
          ),
          timeAndMaterial: rates.timeAndMaterial.filter(
            rate => rate.entity === selectedConsultantEntity || rate.label === CUSTOM_TIER,
          ),
        }
      : rates;

    return {
      dedicated: filteredRates.dedicated.map(rate => ({
        id: rate.id,
        title: rate.label,
        value: `${rate.id}`,
      })),
      timeAndMaterial: filteredRates.timeAndMaterial.map(rate => ({
        id: rate.id,
        title: rate.label,
        value: `${rate.id}`,
      })),
    };
  }, [rates, selectedConsultantEntity]);

  const holidayCalendarList = useMemo(
    (): SelectedItem[] =>
      holidayCalendar.map(calendar => ({
        id: calendar.id,
        title: calendar.label,
        value: `${calendar.id}`,
      })),
    [holidayCalendar],
  );

  const [UpdateBudgetMutation] = useUpdateBudgetMutation({
    onError(e) {
      showSystemError(getErrorMessage(e.message));
    },
  });

  const createAssignment = useCallback(async (data: IAssignmentFormProps, fromBilling) => {
    if (budgetReportId && budgetingSettingId && projectId) {
      const billingPage = Boolean(fromBilling && pageId);

      const newAssignment = await addAssignmentMutation({
        variables: billingPage
          ? mapToSubmitAddAssignmentFromBilling({
              budgetReportId,
              budgetingSettingId,
              fromBilling,
              data,
              projectId,
              billingId: pageId,
            })
          : mapToSubmitAddAssignment({
              budgetReportId,
              budgetingSettingId,
              data,
              projectId,
            }),
      });

      if (!!newAssignment?.data) {
        await UpdateBudgetMutation({
          variables: {
            budgetId: Number(budgetReportId),
          },
          refetchQueries: [billingPage ? 'SingleBillingInfo' : 'getSingleBudgeting'],
        });
      }

      return !!newAssignment.data?.addAssignement.id;
    }

    return false;
  }, []);

  const updateAssignment = useCallback(
    async ({ ...params }: UpdateAssignmentParams) => {
      const { assignmentId, data, fromBilling, ccy, icboTemplateId, billingReportId } = params;
      if (assignmentId && projectId) {
        const billingPage = Boolean(fromBilling && pageId);

        const editAssignmentData = fromBilling
          ? {
              showInBudget: Boolean(data.showInBudget),
              ...mapToSubmitEditAssignment({
                projectId,
                assignmentId,
                data,
                fromBilling,
                budgetingSettingId,
                billingReportId,
                budgetReportId,
                icboTemplateId,
                ccy,
              }),
            }
          : {
              ...mapToSubmitEditAssignment({ projectId, assignmentId, data, fromBilling }),
            };
        const editedAssignment = await editAssignmentMutation({
          variables: { ...editAssignmentData },
          refetchQueries: [billingPage ? 'SingleBillingInfo' : 'getSingleBudgeting'],
        });

        if (!billingPage) {
          await UpdateBudgetMutation({
            variables: {
              budgetId: Number(budgetReportId),
            },
          });
        }

        return !!editedAssignment.data?.editAssignement;
      }

      return false;
    },
    [projectId, budgetingSettingId, budgetReportId, pageId],
  );

  const updateNotes = useCallback(
    async (assignmentId: Maybe<number>, data: string) => {
      if (assignmentId && projectId) {
        const updateNotes = await addNotesMutation({
          variables: {
            assignmentId: assignmentId,
            notes: data,
          },
          refetchQueries: ['getSingleBudgeting', 'SingleBillingInfo'],
        });

        return !!updateNotes.data;
      }

      return false;
    },
    [projectId],
  );

  const modelList = [
    { id: '1', title: ConsultantModelEnum.Dedicated, value: ConsultantModelEnum.Dedicated },
    {
      id: '2',
      title: ConsultantModelEnum.TimeAndMaterial,
      value: ConsultantModelEnum.TimeAndMaterial,
    },
  ];

  return {
    createAssignment,
    updateAssignment,
    deleteAssignment,
    updateNotes,
    holidayCalendarList,
    consultantsList,
    clientsList,
    modelList,
    rateList,
    rates,
    loading: adding || updating || deleting || loadingRates || updatingNotes,
  };
};
